export const GET_SERVICES_STATED = "GET_SERVICES_STATED";
export const GET_SERVICES = "GET_SERVICES";
export const GET_SERVICES_ENDED = "GET_SERVICES_ENDED";
export const ADD_SERVICE_STATED = "ADD_SERVICE_STARTED";
export const ADD_SERVICE = "ADD_SERVICE";
export const ADD_SERVICE_ENDED = "ADD_SERVICE_ENDED";
export const EDIT_SERVICE_STATED = "EDIT_SERVICE_STATED";
export const EDIT_SERVICE = "EDIT_SERVICE";
export const EDIT_SERVICE_ENDED = "EDIT_SERVICE_ENDED";
export const GET_SERVICE = "GET_SERVICE";
export const GET_SERVICE_STATED = "GET_SERVICE_STATED";
export const GET_SERVICE_ENDED = "GET_SERVICE_ENDED";
export const RESET_SERVICE = "RESET_SERVICE";
export const ERROR_SERVICE = "ERROR_SERVICE";
export const GET_ALL_SERVICES_STATED = "GET_ALL_SERVICES_STATED";
export const GET_ALL_SERVICES = "GET_ALL_SERVICES";
export const GET_ALL_SERVICES_ENDED = "GET_ALL_SERVICES_ENDED";
export const GET_SIMILAR_SERVICE = "GET_SIMILAR_SERVICE";
export const GET_SIMILAR_SERVICE_STATED = "GET_SIMILAR_SERVICE_STATED";
export const GET_SIMILAR_SERVICE_ENDED = "GET_SIMILAR_SERVICE_ENDED";
